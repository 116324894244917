<script>
  let Cover = "./img/richcover.png";
</script>

<main>
  <section class="container lg:mx-auto py-10">
    <h1
      class="text-4xl font-black tracking-tighter leading-tight text-center
      mt-20 px-6"
    >
      Bite Sized Book
      <br />
      Publishing.
    </h1>
    <h2
      class="text-lg tracking-normal text-center mb-5 lg:mb-10 mt-10 font-light
      px-6"
    >
      The greatest thinkers, curated by topic, in small formats.
    </h2>
  </section>
  <section class="container mx-auto my-5 lg:my-20 py-5 lg:py-10">
    <div class="flex flex-col lg:flex-row items-stretch">
      <div class="flex-1 px-6 py-2">
        <a
          href="https://www.amazon.com/How-Get-Rich-without-getting-ebook/dp/B0818ZJ1YH/ref=sr_1_3?crid=1CE4F52KY5QF&keywords=how+to+get+rich+without+getting+lucky&qid=1576783962&sprefix=how+to+get+rich+without+getting%2Caps%2C279&sr=8-3"
        >
          <img alt="How to Get Rich cover" src={Cover} />
        </a>
      </div>
      <div class="flex-1 px-6 py-2 leading-relaxed">
        <h2
          class="text-center font-black tracking-tighter text-4xl lg:text-5xl
          leading-none"
        >
          How to Get Rich
        </h2>
        <h3
          class="text-center font-serif text-xl lg:text-2xl leading-none mb-8"
        >
          (without getting lucky)
        </h3>
        <p class="mb-4 font-serif text-lg lg:text-2xl">
          Ever wondered how people get ridiculously rich?
        </p>
        <p class="mb-4 font-serif text-lg lg:text-2xl">
          Do you ever see people living a lifestyle you could never afford? Did
          you ever wonder how they got from here to there? In this book are the
          practical lessons you need to get rich.
        </p>
        <p class="mb-4 font-serif text-lg lg:text-2xl">
          While getting rich is not easy, knowing how to get rich is simple. At
          least with a great teacher that did it himself.
        </p>
        <p class="mb-4 font-serif text-lg lg:text-2xl">
          Naval is a famous Silicon Valley investor, who literally started with
          nothing, and has created fortunes both for himself and many others.
        </p>
        <p class="mb-4 font-serif text-lg lg:text-2xl">
          This book is a collection of his, now world famous, practical advice
          on how to become rich.
        </p>
        <p class="mb-4 font-serif text-lg lg:text-2xl">
          This book was created without any involvement of Naval himself. I have
          personally benefited greatly from his advice and want to share it with
          the world.
        </p>
        <p class="font-sans text-base lg:text-md opacity-75">
          Personal growth & finance. Curated by J Bylund.
        </p>
        <div class="mt-6">
          <a
            class="inline-block bg-black rounded px-6 py-2 my-2 no-underline
            text-lg"
            style="color: #fff; text-decoration: none;"
            href="https://www.amazon.com/How-Get-Rich-without-getting-ebook/dp/B0818ZJ1YH/ref=sr_1_3?crid=1CE4F52KY5QF&keywords=how+to+get+rich+without+getting+lucky&qid=1576783962&sprefix=how+to+get+rich+without+getting%2Caps%2C279&sr=8-3"
          >
            Paperback
            <span class="text-gold">$9.99</span>
          </a>
          <a
            class="inline-block bg-black rounded px-6 py-2 my-2 no-underline
            text-lg"
            style="color: #fff; text-decoration: none;"
            href="https://www.amazon.com/How-Get-Rich-without-getting-ebook/dp/B0818ZJ1YH/ref=sr_1_3?crid=1CE4F52KY5QF&keywords=how+to+get+rich+without+getting+lucky&qid=1576783962&sprefix=how+to+get+rich+without+getting%2Caps%2C279&sr=8-3"
          >
            Kindle
            <span class="text-gold">$3.70</span>
          </a>
        </div>
      </div>
    </div>
  </section>
  <section
    class="container max-w-xl lg:max-w-3xl mx-auto my-5 lg:my-20 py-5 lg:py-10
    px-6"
  >
    <h2
      class="text-center font-black tracking-tighter text-4xl lg:text-5xl
      leading-none"
    >
      Ancient Wisdom Series
    </h2>
    <p class="mb-4 font-serif text-lg lg:text-2xl">
      The greatest thinkers from ancient Greece and Rome, offering practical
      tips on how to deal with timeless human problems such as Anger, Focus and
      Anxiety.
    </p>
    <p class="mb-4 font-serif text-lg lg:text-2xl">
      Planned to be released in the near future.
    </p>
    <p class="font-sans text-md text-gray opacity-75">Personal growth.</p>
    <!-- Begin Mailchimp Signup Form -->
    <link
      href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css"
      rel="stylesheet"
      type="text/css"
    />
    <style type="text/css">
      #mc_embed_signup {
        background: #fff;
        clear: left;
        font: 14px Helvetica, Arial, sans-serif;
        width: 100%;
      }
      /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    </style>
    <div id="mc_embed_signup" class="pt-4">
      <form
        action="https://bitesizedbook.us4.list-manage.com/subscribe/post?u=d8208074b43e4f967a3e91c2e&amp;id=0860c44de8"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
      >
        <div id="mc_embed_signup_scroll" class="text-left">
          <label for="mce-EMAIL">Sign up to be notified on release!</label>
          <input
            type="email"
            value=""
            name="EMAIL"
            class="email"
            id="mce-EMAIL"
            placeholder="email address"
            required
          />
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true">
            <input
              type="text"
              name="b_d8208074b43e4f967a3e91c2e_0860c44de8"
              tabindex="-1"
              value=""
            />
          </div>
          <div class="clear">
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              class="button"
            />
          </div>
        </div>
      </form>
    </div>

    <!--End mc_embed_signup-->
  </section>
  <section class="container mx-auto pb-10">
    <h1
      class="text-3xl font-black tracking-tighter leading-tight text-center
      my-20"
    >
      Bite Sized Book
      <br />
      Publishing.
    </h1>
  </section>
</main>

<style lang="text/postcss">
</style>
